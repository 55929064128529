/*
 * External dependencies
 */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

/**
 * Internal dependencies
 */
import Loader from '../loader';
import { handleUpdatePassword } from '../../lib/auth';
import { getUrlParameter } from '../../lib/stripeUtils';
import InternalLink from '../internal-link';

/**
 * Component
 */
class ResetPasswordForm extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            message: '',
            status: '',
            loading: false,
        };
    }

    componentDidMount() {
        // Set token from URL.
        this.token = getUrlParameter( 'token' );
    }

    handleFormFieldChange = ( event ) => {
        this.setState( { [ event.target.name ]: event.target.value } );
    };

    handleSubmit = ( event ) => {
        event.preventDefault();

        const {
            'new-password-reset-password': newPassword,
            'confirm-new-password-reset-password': confirmNewPassword,
        } = this.state;

        this.setState( {
            loading: true,
        } );

        if ( newPassword !== confirmNewPassword ) {
            this.setState( {
                message: 'Your new passwords do not match.',
                status: 'error',
                loading: false,
            } );

            return;
        }

        handleUpdatePassword( this.token, newPassword, confirmNewPassword )
            .then( ( response ) => {
                if ( response ) {
                    this.setState( {
                        message: response.message ? response.message : '',
                        status: response.status ? response.status : '',
                        loading: false,
                    } );
                }
            } )
            .catch( () => {
                this.setState( {
                    message: 'Can\'t update your password at this time.',
                    status: 'error',
                    loading: false,
                } );
            } );
    };

    render() {
        const {
            message,
            status,
            loading,
        } = this.state;

        if ( ! this.token ) {
            return null;
        }

        const formButtonAsideTextClasses = classNames( 'form-button-aside-text', status ? `is-${status}` : '' );

        return (
            <form className="form" id="reset-password-form" onSubmit={ this.handleSubmit }>
                <fieldset>
                    <div className="input-wrap">
                        <label htmlFor="new-password-reset-password">
                            New password
                            <input
                                name="new-password-reset-password"
                                id="new-password-reset-password"
                                placeholder="Choose a new password"
                                type="password"
                                onChange={ this.handleFormFieldChange }
                                required
                            />
                        </label>
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="new-password-reset-password">
                            Confirm new password
                            <input
                                name="confirm-new-password-reset-password"
                                id="confirm-new-password-reset-password"
                                placeholder="Your new password again"
                                type="password"
                                onChange={ this.handleFormFieldChange }
                                required
                            />
                        </label>
                    </div>
                    <input
                        name="token-reset-password"
                        id="token-reset-password"
                        type="hidden"
                        value={ this.token }
                        required
                    />
                    <div className="input-wrap d-md-flex align-items-md-center">
                        <button className="button is-green has-icon flex-md-shrink-0" type="submit">
                            Change password
                            <FontAwesomeIcon icon="arrow-right" />
                        </button>
                        <span className={ formButtonAsideTextClasses }>
                            { ( loading && <Loader /> ) || message }
                        </span>
                    </div>
                    { status === 'success' && <div className="input-wrap">
                        <InternalLink to="/login">Login to your account</InternalLink>
                    </div> }
                </fieldset>
            </form>
        );
    }
}

export default ResetPasswordForm;
