/**
 * External dependencies
 */
import React from 'react';

/**
 * Stylesheets
 */
import './style.scss';

/**
 * Component
 */
const Loader = () => <div className="loader">&nbsp;</div>;

export default Loader;
