/**
 * External dependencies
 */
import axios from 'axios';

/**
 * Internal dependencies
 */
import { isBrowser } from './auth';

// Fetch Stripe customer subscriptions.
export const fetchStripeCustomerSubscriptions = ( stripeCustomerId ) => {
    return axios.get( `${process.env.GATSBY_BACK_END_URL}/fetch-customer-subscriptions`, {
        params: {
            stripeCustomerId,
        },
    } )
        .then( ( response ) => {
            return response;
        } )
        .catch( ( error ) => {
            return error;
        } );
};

// Fetch Stripe customer coupons.
export const fetchStripeCustomerDiscount = ( stripeCustomerId ) => {
    return axios.get( `${process.env.GATSBY_BACK_END_URL}/fetch-customer-discount`, {
        params: {
            stripeCustomerId,
        },
    } )
        .then( ( response ) => {
            return response;
        } )
        .catch( ( error ) => {
            return error;
        } );
};

// Modify a subscription.
export const modifySubscription = ( subscriptionId, cancelSubscription, email ) => {
    return axios.post( `${process.env.GATSBY_BACK_END_URL}/mentorship-modify`, {
        subscriptionId,
        cancelSubscription,
        email,
    } )
        .then( ( response ) => {
            return response;
        } )
        .catch( ( error ) => {
            return error;
        } );
};

// Fetch Stripe customer cards.
export const fetchStripeCustomerCards = ( stripeCustomerId ) => {
    return axios.get( `${process.env.GATSBY_BACK_END_URL}/fetch-customer-cards`, {
        params: {
            stripeCustomerId,
        },
    } )
        .then( ( response ) => {
            return response;
        } )
        .catch( ( error ) => {
            return error;
        } );
};

export const getUrlParameter = ( paramName ) => {
    if ( ! isBrowser ) {
        return null;
    }

    const name = paramName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.search);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
